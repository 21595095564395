import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import './foot.css'

function Foot () {
  return (
    <div className="footer">
      <div>
        <Link to="/">首页</Link>&emsp;|&emsp;
        <Link to="/operation">运营中心</Link>&emsp;|&emsp;
        <Link to="/join">合作及洽谈</Link>&emsp;|&emsp;
        <Link to="/contact">联系我们</Link>&emsp;|&emsp;
        <a target="_blank" rel="noreferrer" href="http://www.yos168.com/download">应用下载</a>&emsp;|&emsp;
        <Link to="/about">关于我们</Link>
      </div>
      <div>
        <span>粤公网安备 44060602001650号</span>&ensp;|&ensp;
        <a target="_self" rel="noreferrer" href="https://beian.miit.gov.cn/">粤ICP备14093582号</a>&ensp;|&ensp;
        <span>增值电信业务经营许可证:B2-20160109</span>
      </div>
      <div>
        <span>2017 All Rights Reserved. 佛山市纵购电子商务有限公司</span>&ensp;|&ensp;
        <a target="_self" rel="noreferrer" href="//ourchoice.cn/privacy">隐私政策</a>&ensp;|&ensp;
        <a target="_self" rel="noreferrer" href="//ourchoice.cn/public/pc/images/business-license.png">营业执照</a>
      </div>
      <p>Copyright © 2019-2021 ourchoice.cn, All rights reserved.</p>
    </div>
  )
}

export default withRouter(Foot)
