import React from 'react'
import './tool.css'
import qrcode from '../../images/qrcode.png'

export default function Head () {

  const handleClick = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  return (
    <div className="tools">
      <div className="item icon-qrcode">
        <span className="wxs icon-wxs">
          <img src={qrcode} />
        </span>
      </div>
      <div className="item icon-phone">
        <em className="tel tools-transition">400-930-2128</em>
      </div>
      <div className="item icon-arrow-up" onClick={handleClick}></div>
    </div>
  )
}
