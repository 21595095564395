// 序列化参数，创建 URL 编码文本字符串。
export const serialize = (params, baseUrl = '') => {
  let query = ''
  Object.keys(params).forEach(entry => {
    let value = params[entry]

    if (value !== null && value !== undefined && value !== '') {
      // /[\u4e00-\u9fa5]/ encodeURIComponent(value) // 如果直接编码value，会导致英文逗号等也被编码
      query += `${!query ? '' : '&'}${entry}=${value}`
    }
  })

  return baseUrl + (/\?/.test(baseUrl) ? '&' : query ? '?' : '') + query
}

// URL 参数
export const getParams = (location) => {
  const { search } = location || window.location
  const params = search.replace(/^\?+/, '').split('&')
  let paramsObj = {}

  params.forEach(item => {
    const param = item.split('=')

    if (param[0] && param[1]) {
      paramsObj[param[0]] = param[1]
    }
  })

  return paramsObj
}

export const getFlatData = (data) => {
  const newData = []

  function formatData (data) {
    data.forEach(item => {
      newData.push(item)
      if (Array.isArray(item.subCategories)) {
        formatData(item.subCategories)
      }
    })
  }

  formatData(data || [])

  return newData
}

export const getPath = (list = [], id) => {
  const path = []
  const last = list.find(item => item.id === id)

  const getNext = (pid) => {
    const parent = list.find(item => item.id === pid)
    if (parent !== undefined) {
      path.unshift(parent)
      getNext(parent.pid)
    }
  }

  if (last !== undefined) {
    getNext(last.id)
  }

  return path
}

export const toFixedPrice = (num, bits = 2) => {
  const price = parseFloat(num)
  return !isNaN(price) && (typeof price) === 'number' && price > 0 ? (price < 0.01 ? '0.01' : price.toFixed(bits)) : (0).toFixed(bits)
}
