import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import SearchBar from './search-bar'
import './head.css'
import logo from '../../images/logo.png'

function Head () {
  return (
    <div className="header">
      <div className="mod-box">
        <Link to="/"><img className="logo" src={logo} /></Link>
        <div className="item">
          <Link className="inner" to="/">首页</Link>
        </div>
        <div className="item">
          <Link className="inner" to="/about">关于纵购</Link>
          <div className="drop-down">
            <Link className="entry" to="/about">纵购简介</Link>
            <Link className="entry" to="/operation">运营中心</Link>
            <Link className="entry" to="/project">项目介绍</Link>
          </div>
        </div>
        <div className="item">
          <Link className="inner" to="/brand">品牌中心</Link>
        </div>
        <div className="item">
          <Link className="inner" to="/data/car">运营规模</Link>
          <div className="drop-down">
            <Link className="entry" to="/data/car">车辆及路线</Link>
            <Link className="entry" to="/data/area">运营中心仓储面积</Link>
            <Link className="entry" to="/data/user">客户资源及分布情况</Link>
          </div>
        </div>
        <div className="item">
          <Link className="inner" to="/join">招商加盟</Link>
          <div className="drop-down">
            <Link className="entry" to="/join">战略合作</Link>
            <Link className="entry" to="/business">供应商合作</Link>
          </div>
        </div>
        <div className="item">
          <Link className="inner" to="/contact">联系我们</Link>
        </div>
        <div className="item">
          <a className="inner" href="https://www.yos168.com/download">下载APP</a>
        </div>
        <SearchBar></SearchBar>
      </div>
    </div>
  )
}

export default withRouter(Head)
