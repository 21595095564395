import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import route from './router'
import Head from './components/common/head'
import Foot from './components/common/foot'
import Tool from './components/common/tool'
import './fonts/style.css'
import './app.css'
import './images/favicon.ico'

// const fallback = () => (<div>Loading...</div>)

export default function App () {
  const path = window.location.pathname
  const [show, setShow] = useState(true)

  useEffect(() => {
    if (/\/data\/*/.test(path)) {
      setShow(false)
    }
  }, [path])

  return (
    <Router>
      <Head></Head>
      <Switch>
        <Suspense fallback={null}>
          {
            route.map(item => (
              <Route key={item.path} exact path={item.path} component={item.component}/>
            ))
          }
          </Suspense>
      </Switch>
      {
        show &&
          <>
            <Foot></Foot>
            <Tool></Tool>
          </>
      }
    </Router>
  )
}

