import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getParams } from '../../utils'
import './search-bar.css'

export default function SearchBar () {
  const history = useHistory()
  const params = getParams()
  const [keyword, setKeyword] = useState(decodeURIComponent(params.keyword || ''))

  const handleChange = (event) => {
    const value = (event.target.value || '').trim()

    setKeyword(value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    history.push(`/stores/865833?keyword=${keyword}`)
  }

  return (
    <form className="c-search-bar" onSubmit={handleSubmit}>
      <input placeholder="搜索商品" value={decodeURIComponent(keyword)} onChange={handleChange} />
      <i className="icon-search" onClick={handleSubmit}></i>
    </form>
  )
}
