import { lazy } from 'react'

const Home = lazy(() => import('./views/home'))
const About = lazy(() => import('./views/about/about'))
const Operation = lazy(() => import('./views/about/operation'))
const Project = lazy(() => import('./views/about/project'))
const Contact = lazy(() => import('./views/about/contact'))
const Brand = lazy(() => import('./views/brand/brand'))
const Business = lazy(() => import('./views/join/business'))
const Join = lazy(() => import('./views/join/join'))
const DataCar = lazy(() => import('./views/data/car'))
const DataArea = lazy(() => import('./views/data/area'))
const DataUser = lazy(() => import('./views/data/user'))
const Products = lazy(() => import('./views/product/products'))
const Product = lazy(() => import('./views/product/product'))

const route = [{
  path: '/',
  component: Home
}, {
  path: '/about',
  component: About
}, {
  path: '/operation',
  component: Operation
}, {
  path: '/project',
  component: Project
}, {
  path: '/contact',
  component: Contact
}, {
  path: '/brand',
  component: Brand
}, {
  path: '/business',
  component: Business
}, {
  path: '/join',
  component: Join
}, {
  path: '/data/car',
  component: DataCar
}, {
  path: '/data/area',
  component: DataArea
}, {
  path: '/data/user',
  component: DataUser
}, {
  path: '/stores/:merchantCode',
  component: Products
}, {
  path: '/stores/:merchantCode/:productCode',
  component: Product
}, {
  path: '*',
  component: null
}]

export default route
